import { FC, useCallback, useState } from 'react';

import { Loader } from '@travauxlib/shared/src/components/DesignSystem/components/Loader';
import { UserProfile } from '@travauxlib/shared/src/types';

import { useParticipants } from 'features/Deals/api/useParticipants';
import { ConversationParticipant, Deal, ProCompany } from 'types';

import { ParticipantForm } from './ParticipantForm';
import { ParticipantList } from './ParticipantList';

export type ParticipantHandlerMode = 'consult' | 'edit' | 'add';

type ParticipantFormState = {
  opened: boolean;
  participant?: { email: string; name: string };
};

type ParticipantHandlerProps = {
  deal: Deal;
  profile: UserProfile<ProCompany>;
  mode?: ParticipantHandlerMode;
  participantToEdit?: ConversationParticipant;
};

export const ParticipantHandler: FC<ParticipantHandlerProps> = ({
  deal,
  profile,
  mode = 'consult',
  participantToEdit,
}) => {
  const { conversationUuid } = deal;
  const [participantForm, setParticipantForm] = useState<ParticipantFormState>({
    opened: mode !== 'consult',
    participant: participantToEdit,
  });
  const { participants } = useParticipants(deal);

  const handleParticipantClick = useCallback(
    (participant: ConversationParticipant) => {
      if (participant.uuid) {
        setParticipantForm({
          opened: true,
          participant,
        });
      }
    },
    [setParticipantForm],
  );

  const handleCloseParticipantForm = useCallback(() => {
    setParticipantForm({ opened: false });
  }, [setParticipantForm]);

  const handlePlusClick = useCallback(() => {
    setParticipantForm({ opened: true });
  }, [setParticipantForm]);

  return (
    <div className="!h-full !flex !flex-col !relative">
      {participants === undefined ? (
        <Loader />
      ) : (
        <>
          {participantForm.opened ? (
            <div className="w-full">
              <ParticipantForm
                isNew={!participantForm.participant}
                onSubmit={() => setParticipantForm({ opened: false })}
                conversationUuid={conversationUuid}
                participant={participantForm.participant || { email: '', name: '' }}
                handleClose={handleCloseParticipantForm}
              />
            </div>
          ) : (
            <ParticipantList
              deal={deal}
              profile={profile}
              onParticipantClick={handleParticipantClick}
              withDetail
              onPlusClick={handlePlusClick}
            />
          )}
        </>
      )}
    </div>
  );
};
