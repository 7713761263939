import React, { createContext, useEffect } from 'react';

import * as Sentry from '@sentry/react';
import { ErrorBoundary } from 'react-error-boundary';

import { Loader } from '@travauxlib/shared/src/components/DesignSystem/components/Loader';
import {
  ModalContextWrapper,
  ModalRoot,
} from '@travauxlib/shared/src/components/DesignSystem/components/Modal/ModalRoot';
import { DevisHistoryContextWrapper } from '@travauxlib/shared/src/components/DevisHistoryContext';
import { ErrorFallback } from '@travauxlib/shared/src/components/ErrorFallback';
import { ToastContainer } from '@travauxlib/shared/src/components/Notifications';
import { useConsents } from '@travauxlib/shared/src/utils/consents/ConsentsContext';
import { initAcquisitionCookies } from '@travauxlib/shared/src/utils/cookies/initAcquisitionCookies';

import { useProfile } from 'api/useProfile';
import { AppAbility } from 'lib/ability/entities/Ability';
import { IdentityTracking } from 'utils/tracking/IdentityTracking';

import { MainRoutes } from './routes';

import { useProCompany } from '../../api/profileSelectors';

export const AbilityContext = createContext<AppAbility>({} as AppAbility);

export const App: React.FC = () => {
  const { isAuthenticated, isLoading, profile, ability } = useProfile();

  const company = useProCompany();

  const {
    grantedConsents: { isAdConsentGranted },
  } = useConsents();

  useEffect(() => {
    initAcquisitionCookies({ isAdConsentGranted });
  }, []);

  useEffect(() => {
    if (profile) {
      IdentityTracking.identify(profile);
      Sentry.setUser({
        username: profile.fullName || `${profile.firstName} ${profile.lastName}`,
        email: profile.email,
        id: profile.uuid,
        comments: profile.isHemeaEmployee ? 'Action réalisée par un employé hemea' : undefined,
      });
    }

    if (company && !profile.isHemeaEmployee && ENVIRONMENT === 'production') {
      window._cio?.identify({
        id: company.email,
        email: company.email,
      });
    }
  }, [profile?.uuid, profile?.anonymous]);

  return (
    <AbilityContext.Provider value={ability}>
      <ModalContextWrapper>
        <DevisHistoryContextWrapper>
          <ErrorBoundary FallbackComponent={ErrorFallback}>
            <div className="relative min-h-screen">
              <ToastContainer />
              <ModalRoot />
              {isLoading ? (
                <Loader isFullHeightScreen />
              ) : (
                <MainRoutes isAuthenticated={isAuthenticated} />
              )}
            </div>
          </ErrorBoundary>
        </DevisHistoryContextWrapper>
      </ModalContextWrapper>
    </AbilityContext.Provider>
  );
};
