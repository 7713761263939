import { Loader } from '@travauxlib/shared/src/components/DesignSystem/components/Loader';
import { loadable } from '@travauxlib/shared/src/utils/loadable';

import { ChantiersRecap } from './components/RecapBoard/components/ChantiersRecap/Loadable';
import { DevisRecap } from './components/RecapBoard/components/DevisRecap/Loadable';
import { FacturesRecap } from './components/RecapBoard/components/FacturesRecap/Loadable';

export const { Dashboard: Index } = loadable(() => import('./index'), {
  fallback: <Loader isFullHeightScreen />,
});

export const Dashboard = {
  Index,
  DevisRecap,
  FacturesRecap,
  ChantiersRecap,
};
