import React, { useState } from 'react';

import { InteractiveTags } from '@travauxlib/shared/src/components/DesignSystem/components/Tag/InteractiveTags';
import { TagOption } from '@travauxlib/shared/src/components/DesignSystem/components/Tag/types';
import { DevisStatuses } from '@travauxlib/shared/src/types';

import { DevisModalWarningStatusSigned } from 'components/DevisModalWarningStatusSigned';
import { useUpdateStatus } from 'features/Devis/api/useUpdateStatus';
import { Devis } from 'types';

const options: TagOption[] = [
  { label: 'Brouillon', variant: 'light', value: DevisStatuses.draft },
  { label: 'Envoyé', variant: 'info', value: DevisStatuses.sent },
  { label: 'Envoyé', variant: 'info', value: DevisStatuses.sent_hemea },
  { label: 'Signé', variant: 'success', value: DevisStatuses.signed },
  { label: 'Annulé', variant: 'warning', value: DevisStatuses.canceled },
  { label: 'Refusé', variant: 'error', value: DevisStatuses.rejected },
  // We display it nicely for pro see https://github.com/travauxlib/travauxlib/issues/11145
  { label: 'Envoyé', variant: 'info', value: DevisStatuses.rejected_hemea },
  { label: 'À réviser', variant: 'warning', value: DevisStatuses.to_be_revised },
  { label: 'À réviser', variant: 'warning', value: DevisStatuses.to_be_revised_hemea },
];

const signedOrCanceledOptions: TagOption[] = [
  { label: 'Signé', variant: 'success', value: DevisStatuses.signed },
  { label: 'Annulé', variant: 'warning', value: DevisStatuses.canceled },
];

export const StatusTagsDevis: React.FC<{
  devis: Devis;
  handleTracking?: (props: { token: string; status: DevisStatuses }) => void;
  disabled: boolean;
  fixedFloating?: boolean;
}> = ({ devis, handleTracking, disabled, fixedFloating }) => {
  const [showWarningModal, setShowWarningModal] = useState(false);
  const updateStatus = useUpdateStatus();
  const optionsToShow =
    devis.status === DevisStatuses.signed || devis.status === DevisStatuses.canceled
      ? signedOrCanceledOptions
      : options;

  const handleUpdateStatus = (newStatus: DevisStatuses): void => {
    handleTracking?.({
      token: devis.token,
      status: newStatus,
    });
    updateStatus({ ...devis, status: newStatus });
  };

  return (
    <div onClick={event => event.stopPropagation()}>
      <InteractiveTags
        fixedFloating={fixedFloating}
        value={devis.status}
        onChange={(tag: DevisStatuses) => {
          if (tag === DevisStatuses.signed && devis.status !== DevisStatuses.canceled) {
            setShowWarningModal(true);
            return;
          }
          handleUpdateStatus(tag);
        }}
        options={optionsToShow}
        disabled={disabled}
      />
      <DevisModalWarningStatusSigned
        isOpen={showWarningModal}
        handleConfirm={() => {
          handleUpdateStatus(DevisStatuses.signed);
          setShowWarningModal(false);
        }}
        closeModal={() => setShowWarningModal(false)}
      />
    </div>
  );
};
