import React, { Suspense } from 'react';

import { Loader } from '@travauxlib/shared/src/components/DesignSystem/components/Loader';

import { CentraleAchatSideBar } from './CentraleAchat/CentraleAchatSideBar';
import { ParametersSideBar } from './ParametersSideBar';

const ProClientsAndConsultationOffers = React.lazy(() =>
  import('./ProClientsAndConsultationOffers').then(module => ({
    default: module.ProClientsAndConsultationOffers,
  })),
);

type Props = {
  matchCentraleAchatRoute: boolean;
  matchParametersRoutes: boolean;
};

export const SecondaryProSidebarContent: React.FC<Props> = ({
  matchCentraleAchatRoute,
  matchParametersRoutes,
}) => {
  if (matchParametersRoutes) {
    return <ParametersSideBar />;
  }
  if (matchCentraleAchatRoute) {
    return <CentraleAchatSideBar />;
  }
  return (
    <Suspense fallback={<Loader />}>
      <ProClientsAndConsultationOffers />
    </Suspense>
  );
};
