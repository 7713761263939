import { Loader } from '@travauxlib/shared/src/components/DesignSystem/components/Loader';
import { loadable } from '@travauxlib/shared/src/utils/loadable';

import { Clients } from './components/Clients/Loadable';
import { Contractors } from './components/Contractors/Loadable';

const { Contacts: Index } = loadable(() => import('./index'), {
  fallback: <Loader isFullHeightScreen />,
});

export const Contacts = {
  Index,
  Clients,
  Contractors,
};
