import React from 'react';

import DotsMenu from '@travauxlib/shared/src/components/DesignSystem/assets/DotsMenu.svg?react';
import { ActionList } from '@travauxlib/shared/src/components/DesignSystem/components/ActionList';
import { IconButton } from '@travauxlib/shared/src/components/DesignSystem/components/Buttons/IconButton';
import { Loader } from '@travauxlib/shared/src/components/DesignSystem/components/Loader';
import { useOpenModal } from '@travauxlib/shared/src/components/DesignSystem/components/Modal';
import { useIsTabletOrMobile } from '@travauxlib/shared/src/hooks/useIsTabletOrMobile';

import { useIsHemeaEmployee } from 'api/profileSelectors';
import { useProfile } from 'api/useProfile';
import { useUpdateDeal } from 'features/Deals/api/useUpdateDeal';
import { useOpenConversationInfoModal } from 'features/Deals/hooks/useOpenConversationInformationModal';
import { useOpenProClientDetailsModal } from 'features/Deals/hooks/useOpenProClientDetailsModal';
import { useProClient } from 'features/ProClients/api/useProClient';
import { Deal, DealOwner } from 'types';

import { DealOwnerInfo } from './DealOwnerInfo';

import { useImportEstimations } from '../../api/useImportEstimations';
import { useOpenArchiveDealModal } from '../../hooks/useOpenArchiveDealModal';
import { useOpenDealClientDetailsModal } from '../../hooks/useOpenDealClientDetailsModal';

type Props = {
  deal: Deal;
};

const useOpenDealOwnerInfoModal = (): ((dealOwner: DealOwner) => void) => {
  const openModal = useOpenModal();

  return (dealOwner: DealOwner) =>
    openModal(DealOwnerInfo, {
      dealOwner,
      closable: true,
    });
};

export const DealActions: React.FC<Props> = ({ deal }) => {
  const { profile } = useProfile();
  const openConversationInformationModal = useOpenConversationInfoModal();
  const { updateDeal } = useUpdateDeal();
  const openProClientDetailsModal = useOpenProClientDetailsModal();
  const openDealClientDetailsModal = useOpenDealClientDetailsModal();
  const openArchiveProjectModal = useOpenArchiveDealModal();
  const openDealOwnerInfoModal = useOpenDealOwnerInfoModal();
  const importEstimations = useImportEstimations();
  const isTabletOrMobile = useIsTabletOrMobile();
  const isHemeaEmployee = useIsHemeaEmployee();
  const proClient = useProClient(deal.proClientUuid);

  if (!proClient) {
    return <Loader />;
  }

  const options: Array<{
    type: 'action';
    label: string;
    onClick: () => void;
    hide?: boolean;
  }> = [
    {
      type: 'action',
      label: 'Modifier les informations du client',
      onClick: () => openProClientDetailsModal(proClient),
    },
    {
      type: 'action',
      label: 'Modifier les informations du chantier',
      onClick: () => openDealClientDetailsModal({ deal }),
    },
    {
      type: 'action',
      label: 'Créer un nouveau chantier pour ce client',
      onClick: () =>
        openDealClientDetailsModal({
          initialValues: {
            customerName: proClient.name || '',
            customerEmail: proClient.email,
            customerPhoneNumber: proClient.phoneNumber,
            proClientUuid: proClient.uuid,
          },
        }),
    },
    {
      type: 'action',
      label: 'Informations de la conversation',
      onClick: () => {
        openConversationInformationModal({ dealUuid: deal.uuid, profile });
      },
    },
    {
      type: 'action',
      label: `${deal.archived ? 'Désarchiver ' : 'Archiver '} ce chantier`,
      onClick: () =>
        openArchiveProjectModal({
          label: 'le chantier ?',
          archived: deal.archived,
          handleAccept: async () => {
            await updateDeal({ ...deal, archived: !deal.archived });
          },
        }),
    },
    {
      type: 'action',
      hide: !deal.dealOwner || !isTabletOrMobile,
      label: 'Voir le contact hemea',
      onClick: () => openDealOwnerInfoModal(deal.dealOwner!),
    },
    {
      type: 'action',
      hide: !isHemeaEmployee || !deal.isHemea,
      label: 'Importer les estimations du client',
      onClick: () => importEstimations(deal.uuid),
    },
  ];

  return (
    <>
      <ActionList
        position={isTabletOrMobile ? 'bottom-end' : 'bottom'}
        small={isTabletOrMobile}
        items={options}
        trigger={(toggle, referenceProps) => (
          <IconButton
            {...referenceProps}
            size="lg"
            className="shrink-0"
            onClick={toggle}
            data-testid="open-deal-actions"
          >
            <DotsMenu />
          </IconButton>
        )}
      />
    </>
  );
};
