import { Loader } from '@travauxlib/shared/src/components/DesignSystem/components/Loader';
import { loadable } from '@travauxlib/shared/src/utils/loadable';

import { BaseDePrix as BaseDePrixComponent } from './components/BaseDePrix/Loadable';
import { FournitureList } from './components/FournitureList/Loadable';
import { MainOeuvreList } from './components/MainOeuvreList/Loadable';
import { MateriauList } from './components/MateriauList/Loadable';

export const { BaseDePrix: Index } = loadable(() => import('.'), {
  fallback: <Loader />,
});

export const BaseDePrix = {
  Index,
  MainOeuvreList,
  MateriauList,
  FournitureList,
  BaseDePrix: BaseDePrixComponent,
};
