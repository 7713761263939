import React, { useMemo } from 'react';

import _isEmpty from 'lodash/isEmpty';
import _keyBy from 'lodash/keyBy';

import HandyManOutline from '@travauxlib/shared/src/components/DesignSystem/assets/HandyManOutline.svg?react';
import InfoCircleOutline from '@travauxlib/shared/src/components/DesignSystem/assets/InfoCircleOutline.svg?react';
import PiggyBank from '@travauxlib/shared/src/components/DesignSystem/assets/PiggyBank.svg?react';
import { ColoredCircle } from '@travauxlib/shared/src/components/DesignSystem/components/ColoredCircle';
import { EURCurrency } from '@travauxlib/shared/src/components/EURCurrency';
import { MultilineParagraph } from '@travauxlib/shared/src/components/MultilineParagraph';
import { Toggles } from '@travauxlib/shared/src/features/Configurateur/components/Toggles';
import { BaseDePrix } from '@travauxlib/shared/src/features/Configurateur/types';
import { Summary } from '@travauxlib/shared/src/features/Project/components/Summary';

import { useGetBaseDePrix } from 'features/BaseDePrix/api/useGetBaseDePrix';
import { EmptyConfigurationForm } from 'features/Deals/features/Estimation/components/EmptyConfigurationForm';
import { ConsultationOffer } from 'types';

import { DescriptifHeader } from './DescriptifHeader';
import { Documents } from './Documents';
import { RdvSelectorContainer } from './RdvSelectorContainer';

import { RejectionPayload } from '../api/useConsultationOffer';
import { calculateExpirationRatio } from '../utils';

type Props = {
  consultationOffer: ConsultationOffer;
  submitting: boolean;
  handleReject: (payload: RejectionPayload) => Promise<void>;
  handleAccept: (rdvAt?: string) => Promise<{ dealUuid: string }>;
};

export const Descriptif: React.FC<Props> = ({
  consultationOffer,
  handleReject,
  submitting,
  handleAccept,
}) => {
  const { lines } = useGetBaseDePrix();

  const baseDePrix = useMemo(
    () => _keyBy(lines, item => item.normalizedIdentifier),
    [lines],
  ) as BaseDePrix;

  const {
    formattedLocality,
    expiresIn,
    expiresAt,
    createdAt,
    budgetClient,
    showConfigurationRecap,
  } = consultationOffer;
  const expires = calculateExpirationRatio(createdAt, expiresAt);

  return (
    <>
      <DescriptifHeader
        baseDePrix={baseDePrix}
        configuration={consultationOffer.configuration}
        formattedLocality={formattedLocality}
        dealOwner={consultationOffer?.dealOwner}
        showConfigurationRecap={showConfigurationRecap}
      />
      <div className="mb-xl">
        <div className="flex items-center mb-md">
          <ColoredCircle className="mr-xs" size="sm">
            <InfoCircleOutline />
          </ColoredCircle>
          <h3 className="font-bold text-h4 mb-0">Informations du chantier</h3>
        </div>
        <MultilineParagraph text={consultationOffer.descriptif} />
      </div>
      <div className="mb-xl">
        <div className="flex items-center mb-md">
          <ColoredCircle className="mr-xs" size="sm">
            <PiggyBank />
          </ColoredCircle>
          <h3 className="font-bold text-h4 mb-0">Budget</h3>
        </div>
        <div className="text-b2">
          Budget estimé du client : <EURCurrency amount={budgetClient} />
        </div>
      </div>
      {consultationOffer.configuration && showConfigurationRecap ? (
        <>
          <EmptyConfigurationForm configuration={consultationOffer.configuration}>
            <div className="mb-xl">
              <Summary
                googleApiKey={APP_CONFIG.googleApiKey}
                project={consultationOffer.configuration}
              />
            </div>
          </EmptyConfigurationForm>
          {!_isEmpty(consultationOffer.configuration.answers) && (
            <div className="mb-xl">
              <Toggles
                readOnly
                baseDePrix={baseDePrix}
                configuration={consultationOffer.configuration}
              />
            </div>
          )}
        </>
      ) : null}
      <Documents files={consultationOffer.files} />
      {consultationOffer.expiresIn && (
        <>
          <div className="flex items-center mb-md">
            <ColoredCircle className="mr-xs" size="sm">
              <HandyManOutline />
            </ColoredCircle>
            <h3 className="font-bold text-h4 mb-0">Proposition de chantier</h3>
          </div>
          <RdvSelectorContainer
            consultationOffer={consultationOffer}
            handleAccept={handleAccept}
            submitting={submitting}
            handleReject={handleReject}
            time={expires.time}
            ratio={expires.ratio}
            expiresIn={expiresIn}
            configuration={consultationOffer.configuration}
            baseDePrix={baseDePrix}
          />
        </>
      )}
    </>
  );
};
