import React from 'react';

import classNames from 'classnames';
import { Form } from 'react-final-form';
import { v4 as uuidV4 } from 'uuid';

import Attachment from '@travauxlib/shared/src/components/DesignSystem/assets/Attachment.svg?react';
import Cross from '@travauxlib/shared/src/components/DesignSystem/assets/CrossSymbol.svg?react';
import SendSvg from '@travauxlib/shared/src/components/DesignSystem/assets/Send.svg?react';
import { TextareaField } from '@travauxlib/shared/src/components/DesignSystem/components/Textarea/Field';
import { useIsTabletOrMobile } from '@travauxlib/shared/src/hooks/useIsTabletOrMobile';

import { ImgThumbnail } from 'components/ImgThumbnail';
import { Deal, MessageAttachment } from 'types';
import { imgExtensions } from 'utils/constants';

import { useConversation } from '../../api/useConversation';
import { ConversationDropZone } from '../ConversationDropZone';
import { ConversationMobileMenu } from '../ConversationMobileMenu';

type FormValues = {
  content: string;
  attachments: MessageAttachment[];
};

type Props = {
  deal: Deal;
};

const initialValues = { content: '', attachments: [] };

export const Send: React.FC<Props> = ({ deal }) => {
  const { conversationUuid } = deal;
  const { sendMessage, deleteAttachment } = useConversation(conversationUuid);
  const isTabletOrMobile = useIsTabletOrMobile();
  return (
    <Form<FormValues>
      initialValues={initialValues}
      onSubmit={async (values, form) => {
        if (!values.content && values.attachments.length === 0) {
          return;
        }
        await sendMessage({ ...values, conversationUuid, uuid: uuidV4() });
        setTimeout(() => form.reset());
      }}
    >
      {({ handleSubmit, form, values }) => (
        <form className="flex items-end relative" onSubmit={handleSubmit}>
          {isTabletOrMobile ? (
            <ConversationMobileMenu deal={deal} />
          ) : (
            <ConversationDropZone
              conversationUuid={conversationUuid}
              trigger={({ getRootProps, getInputProps }) => (
                <div {...getRootProps()}>
                  <input data-testid="drop-file" {...getInputProps()} />
                  <button type="button" className="p-0 bg-transparent">
                    <Attachment
                      className="w-xl h-xl text-primary mb-sm mr-md"
                      data-testid="add-pj"
                    />
                  </button>
                </div>
              )}
            />
          )}
          <div className={classNames('relative w-full border rounded')}>
            <button
              disabled={values.attachments.length === 0 && !values.content?.length}
              type="submit"
              className="absolute mb-xs p-0 bg-transparent z-10 bottom-0 right-[0.5rem]"
            >
              <SendSvg data-testid="send" className="w-xl h-xl text-primary" />
            </button>
            {values.attachments.length !== 0 && (
              <div className="p-xmd flex flex-wrap">
                {values.attachments.map(attachment => (
                  <div
                    className="pl-sm pr-xxl py-sm mx-xxs mb-sm flex items-center bg-light relative"
                    key={attachment.url}
                  >
                    {imgExtensions.some(ext => attachment.filename.toLowerCase().endsWith(ext)) && (
                      <ImgThumbnail src={attachment.url} />
                    )}
                    {attachment.filename}
                    <div className="absolute top-[0.75rem] right-[0.75rem] z-10">
                      <Cross
                        className="h-lg w-lg cursor-pointer"
                        onClick={async () => {
                          await deleteAttachment(attachment.filekey);
                          form.change(
                            'attachments',
                            values.attachments.filter(el => el.filename !== attachment.filename),
                          );
                        }}
                      />
                    </div>
                  </div>
                ))}
              </div>
            )}
            <TextareaField
              className="max-h-[12.5rem] overflow-auto"
              id="messageInput"
              name="content"
              label="Écrire un message"
            />
          </div>
        </form>
      )}
    </Form>
  );
};
