import React from 'react';

import { Form } from 'react-final-form';

import { DropdownField } from '@travauxlib/shared/src/components/DesignSystem/components/Dropdown/Field';
import { Loader } from '@travauxlib/shared/src/components/DesignSystem/components/Loader';
import { ModalContent } from '@travauxlib/shared/src/components/DesignSystem/components/Modal';
import { NumberInputField } from '@travauxlib/shared/src/components/DesignSystem/components/NumberInput/Field';
import { required } from '@travauxlib/shared/src/utils/form';

import { useContractors } from 'features/Contacts/api/useContractors';
import { useCreateBonCommande } from 'features/Devis/api/useCreateBonCommande';
import { Devis } from 'types';

import { NoContractorModal } from './NoContractorModal';

type Props = {
  devis: Devis;
  handleClose?: () => void;
  openCreateBonCommandeModal: (payload: { devis: Devis }) => void;
};

export const CreateBonCommandeModalContent: React.FC<Props> = ({
  devis,
  handleClose,
  openCreateBonCommandeModal,
}) => {
  const { createBonCommande } = useCreateBonCommande();
  const { contractors, isLoading } = useContractors();
  const contractorsOptions = contractors.map(contractor => ({
    label: contractor.name,
    value: contractor.uuid,
  }));

  if (isLoading) {
    return <Loader />;
  }
  if (!contractorsOptions.length) {
    return (
      <NoContractorModal
        handleClose={handleClose}
        openCreateBonCommandeModal={() => openCreateBonCommandeModal({ devis: devis })}
      />
    );
  }

  return (
    <Form<{ marge: number; contractorUuid: string }>
      onSubmit={({ marge, contractorUuid }) => {
        createBonCommande({ devis, marge, contractorUuid });
      }}
      initialValues={{ marge: 0, contractorUuid: contractorsOptions[0].value }}
    >
      {({ handleSubmit, submitting, valid }) => (
        <ModalContent
          handleSubmit={handleSubmit}
          validateAction={{
            label: 'Créer',
            disabled: !valid || submitting,
            type: 'submit',
            loading: submitting,
          }}
          cancelAction={{
            label: 'Annuler',
            onClick: handleClose,
          }}
        >
          <div className="mb-xs">
            <DropdownField
              id="contractorUuid"
              name="contractorUuid"
              label="Prestataire"
              options={contractorsOptions}
              validate={required}
              disableInput
            />
          </div>
          <NumberInputField
            max={100}
            id="marge"
            name="marge"
            label="Marge dégagée supplémentaire"
            suffix="%"
            validate={required}
          />
        </ModalContent>
      )}
    </Form>
  );
};
